import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/hamb-bacalhau.css'

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import atumcover from "../images/atum-cover.png" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-bac.png" // Tell webpack this JS file uses this image
import $ from 'jquery' // important: case sensitive.
import StopMotion from "../images/StopMotion.mp4"


import scrollTo from 'gatsby-plugin-smoothscroll';

function clickHandler(){
  $("#modal1").css("display", "block");

  $("#modal1").css("opacity", "1");
}
function clickHandler2(){
  $("#modal2").css("display", "block");

  $("#modal2").css("opacity", "1");
}

function closeHandler(){
 
  $("#modal1").css("opacity", "0");
  $("#modal1").css("display", "none");

}

function closeHandler2(){
  $("#modal2").css("opacity", "0");
  $("#modal2").css("display", "none");


}


const hamburguerBacalhau = () => (
  <Layout>
    <SEO title="Peixe Fácil - Hambúrguer de Bacalhau" />

    <div id="modal1" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo absara">Fonte de Proteína</p>
          </div>
          <div className="right-col">

          <button className="titulo blue-marine sneak-regular" onClick={closeHandler}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           A proteina é um dos macronutrientes que compõem a dieta alimentar. É principalmente encontrada em alimentos de origem animal apesar de também estar presente noutras fontes à base de plantas como legumes, nozes e sementes. Uma dieta equilibrada deve assegurar uma adequada ingestão de proteina assim como de aminoácidos essenciais.<br></br><br></br> A proteina tem um papel fundamental na:<br></br><br></br>
<b>• Manutenção de massa muscular;</b><br></br>
<b>• Crescimento, reparação e manutenção de células;</b><br></br>
<b>• Reações metabólicas;</b><br></br>
<b>• Resposta imunitária.</b><br></br><br></br>
Uma dieta rica em proteina pode beneficiar a rápida recuperação após o exercício, ajudar na manutenção de peso e sensação de maior saciedade.
           </p>
        </div>
      </div>

    </div>

    <div id="modal2" className="modal">
      
      <div className="modal-container">
        <div className="header-modal">
          <div className="left-col">
          <p className="titulo blue-marine absara">Baixo teor de gordura</p>
          </div>
          <div className="right-col">

          <button className="titulo sneak-regular" onClick={closeHandler2}>Fechar</button>
          </div>
        </div>
        <div className="body-modal">
           <p className="descricao sneak-regular">
           Com base na sua composição a gordura pode ser classificada como gordura:<br></br><br></br>
           <b>• Saturada</b><br></br>
           <b>• Polinsaturada</b><br></br>
           <b>• Monoinsaturada</b><br></br>
           <b>• Trans</b><br></br>
           <br></br><br></br>
           As gorduras saturadas e trans aumentam os níveis de colesterol LDL, que se encontra associado
ao risco mais elevado de desenvolvimento de doenças cardiovasculares. Assim, dietas ricas em
gordura podem ter um impacto negativo no organismo, estando estas frequentemente
associadas não só a doenças cardiovasculares como também a diabetes e alguns tipos de
cancro. Desta forma, é recomendável uma dieta equilibrada com uma redução de dietas ricas
em gordura promovendo assim um estilo de vida saudável.
           </p>
        </div>
      </div>

    </div>

    <div className="wrapper-bg-bac">
        <div className="center-col-bg">
        <div className="logo-brand">
        <img src={logonew} alt="" />

        </div>

        </div>
     
      </div>

    <div id="section-1-bac">
      <div className="wrapper-container">
   
        
        {/* <div className="mobile-logo">
            <img src={logonew} alt="Logo" />
          </div> */}
          
        <div className="titulo absara blue-marine">
          <p> Hambúrguer de Bacalhau</p>
        </div>
          <div className="descricao sneak-regular opacity">
            <p> Reinventámos, a iguaria tão apreciada em Portugal! Agora, pode adaptar o bacalhau ao seu quotidiano, da forma mais simples. </p>
          </div>
      </div>
      <div className="container-video">
          <div className="left-col-video">
          <video className="video-class" preload='auto' controls autoPlay loop muted playsInline>
            <source src={StopMotion} type="video/mp4" />
          </video>

          </div>
          <div className="right-col-video">
            <div className="container-right-col">
            <p className="absara titulo blue-marine">Confeção</p>
            <p className="sneak-regular opacity ">Siga as instruções deste pequeno video para conseguir tirar o melhor partido destes maravilhosos hambúrgueres.</p>
            </div>
          </div>
      </div>
      <div id="caracts-section-bac">
      <div className="caracts-wrapper">
        <div className="left-col">
          <div className="text-element">
            <div className="titulo absara blue-marine">
              Nutrição
            </div>
            <div className="value sneak-regular ">
            <button onClick={clickHandler}><span className="span-class">Fonte de proteína</span></button><br></br>
            <button onClick={clickHandler2}><span className="span-class">Baixo teor de gordura</span></button><br></br>

              
            </div>
          </div>

        </div>
        <div className="right-col">
        <div className="text-element">
            <div className="titulo absara blue-marine">
              Método de confeção
            </div>
            <div className="value">
              <div className="icon">
              <img src={bacicones} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>15 minutos no forno a temperatura de 180º Celsius</p>

              </div>
            

              
            </div>
            <div className="value">
              <div className="icon">
              <img src={bacicones2} alt="Logo" />
              </div>
              <div className="value sneak-regular opacity white">
                <p>5 minutos na frigideira</p>

              </div>
            

              
            </div>
          </div>
          
          </div>
      </div>
      </div>
    </div>
    <div className="wrapper-3-bac">
    <div className="btn-div">
    <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>

          </div>
    </div>

  </Layout>
)

export default hamburguerBacalhau
